import { O } from '@angular/cdk/keycodes';
import { Component, inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { OrderService } from '@pisci/data-orders';
import { RequestService } from '@pisci/requestManager';


import { catchError, EMPTY, map, mergeMap, Observable, tap } from 'rxjs';
import { pick } from 'lodash';
import { Order } from '@app/data-interfaces';
@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
})
export class OrderDetailComponent {
  order$: Observable<Order>;
  editing = false;
  private fb = inject(UntypedFormBuilder);
  id = '';
  formGroup = new UntypedFormGroup({
    lines: new UntypedFormArray([]),
    billing_address: this.getAddressForm(),
    shipping_address: this.getAddressForm(),
    newProductId: new UntypedFormControl(),
    newLines: new UntypedFormArray([]),
    newCustomLines: new UntypedFormArray([]),
  });
  get newCustomLines() {
    return this.formGroup.get('newCustomLines') as UntypedFormArray;
  }

  get newLines() {
    return this.formGroup.get('newLines') as UntypedFormArray;
  }

  private getAddressForm() {
    return this.fb.group({
      firstname: this.fb.control(''),
      lastname: this.fb.control(''),
      name: this.fb.control(''),
      street: this.fb.control('', Validators.required),
      street_number: this.fb.control('', Validators.required),
      street_2: this.fb.control(''),
      zip: this.fb.control('', Validators.required),
      city: this.fb.control('', Validators.required),
      country: this.fb.control('AT'),
    });
  }
  constructor(
    private route: ActivatedRoute,
    private orderService: OrderService,
    private rm: RequestService,
    private router: Router
  ) {
    this.order$ = this.route.params.pipe(
      mergeMap((params) => {
        if (!params['id'] || params['id'] == '') {
          return EMPTY;
        }
        this.id = params['id'];
        this.orderService.loadOrder(this.id).subscribe();
        return this.orderService.getOrder(params['id']).pipe(
          tap((order) => {
            const lineForm = this.formGroup.get('lines') as UntypedFormArray;
            lineForm.clear();
            order.line_items.forEach((lineItem) => {
              lineForm.push(
                new UntypedFormGroup({
                  id: new UntypedFormControl(lineItem.id),
                  quantity: new UntypedFormControl(lineItem.quantity),
                  amountGross: new UntypedFormControl(lineItem.amount_gross),
                })
              );
            });
            this.formGroup.patchValue(
              pick(order, ['billing_address', 'shipping_address'])
            );
          })
        );
      })
    );
  }


  clickAddLine() {
    const lineForm = this.formGroup.get('newCustomLines') as UntypedFormArray;
    lineForm.push(
      new UntypedFormGroup({
        name: new UntypedFormControl('Zeile'),
        quantity: new UntypedFormControl(1),
        amountGross: new UntypedFormControl(1),
        amountNet: new UntypedFormControl(1),
      })
    );
  }
  clickAddProduct() {
    const id = this.formGroup.value.newProductId;
    this.addProductLine(id);
  }
  addProductLine(id: string) {
    const lineForm = this.formGroup.get('newLines') as UntypedFormArray;
    lineForm.push(
      new UntypedFormGroup({
        productId: new UntypedFormControl(id),
        quantity: new UntypedFormControl(1),
      })
    );
  }
  clickEditable() {
    this.editing = true;
  }
  clickSave() {
    const body: any = {
      lines: [],
      ...(pick(this.formGroup.value, ['shipping_address', 'billing_address'])),
    };

    const lines = this.formGroup.get('lines') as UntypedFormArray;
    if (lines.dirty) {
      lines.controls.forEach((lineFormGroup) => {
        if (lineFormGroup.dirty) {
          body.lines.push(lineFormGroup.value);
        }
      });
    }
    const newCustomLines = this.formGroup.get(
      'newCustomLines'
    ) as UntypedFormArray;
    newCustomLines.controls.forEach((lineFormGroup) => {
      const line = lineFormGroup.value;
      line.amountNet = line.amountGross;
      body.lines.push(line);
    });

    const newLines = this.formGroup.get('newLines') as UntypedFormArray;
    newLines.controls.forEach((lineFormGroup) => {
      body.lines.push(lineFormGroup.value);
    });

    this.orderService
      .update(this.id, body)
      .pipe(
        catchError(() => {
          this.orderService.loadOrder(this.id).subscribe();
          this.editing = false;
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.editing = false;
      });
  }

  clickDelete() {
    const res = confirm('Wollen sie die Bestellung wirklich löschen?');
    if (res === false) {
      return;
    }
    this.orderService.deleteOrder(this.id).subscribe(() => {
      this.router.navigateByUrl('/orders');
    });
  }
}
