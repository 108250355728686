import { Component, Input, OnInit, Optional } from '@angular/core';
import { CartService, SubscriptionService } from '@app/shared/data-cart2';
import { authLoginAction, AuthService } from '@pisci/requestManager';
import { Actions } from '@ngneat/effects-ng';
import { distinctUntilChanged, map, mergeMap, Observable, Subject, take } from 'rxjs';
import { ProductCatalogService } from '@app/shared/data-productscatalog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProductService } from '@app/shared/data-products';
import { ISubscription, ISubscriptionAction, ISubscriptionActionType, Product } from '@app/data-interfaces';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionModalComponent } from '@cw-addtocart';
import { filterNil, isString } from '@ngneat/elf';
import { SubscriptionConfigService } from '@cw-addtocart';
import { parseISO } from 'date-fns';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs/operators';
import { Utils } from '@app/utils';
import { formatDate } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'app-subscription-cart-list',
  templateUrl: './subscription-cart-list.component.html',
  styleUrls: ['./subscription-cart-list.component.scss']
})
export class SubscriptionCartListComponent implements OnInit {
  dataSourceSubscription$;
  isLoggedIn$;
  subRemoves$: Observable<any[]>;
  @Input()
  disableActions = false;

  clickDelete$ = new Subject<ISubscription>();

  constructor(
    private subService: SubscriptionService,
    private authService: AuthService,
    private actions: Actions,
    private productCatService: ProductCatalogService,
    private cartService: CartService,
    private productService: ProductService,
    private modalService: MatDialog,
    @Optional() private subConfig: SubscriptionConfigService
  ) {


    this.clickDelete$.pipe(
      distinctUntilChanged(
        (s2, s1) => {
          console.log(s2, s1);
          if (s1.id == s2.id && s1.id!=null) {
            return true;
          }
          return (s1.uuid ?? '' + s1.id ?? '') == (s2.uuid ?? '' + s2.id ?? '');
        }),
      tap((sub) => {
        console.log('clickdelete', sub);
        const line: Partial<ISubscription> = sub;
        const type: ISubscriptionActionType = 'remove';
        this.getProduct(sub.product_id).pipe(filterNil(), take(1)).subscribe(
          (product: Product) => {
            this.cartService.addSubscriptionAction(
              { action: type, subscription: line },
              product
            );
          });
      }), takeUntilDestroyed()).subscribe();
    this.isLoggedIn$ = this.authService.selectIsLoggedIn();
    const subs$ = this.cartService.selectOrderDTO().pipe(
      map((orderDTO) => orderDTO.subscriptions),
      filterNil()
    );

    this.subRemoves$ = this.cartService
      .selectSubscriptionChanges()
      .pipe(map((lines) => lines.filter((line) => line.action === 'remove')),
        tap(lines => {
          const product_ids:number[] = lines.map(line => line.subscription.product_id).filter(v => v !== undefined) as number[];
          if (product_ids != undefined)
            this.productService.loadByIds(product_ids).subscribe();

        }));

    this.dataSourceSubscription$ = subs$;
    const productGetter$ = subs$
      .pipe(
        mergeMap((subs: ISubscription[]) => {
          const ids = subs.map((sub) => sub.product_id);
          return this.productService.loadByIds(ids);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  ngOnInit() {
    this.subService.loadMySubscriptions().subscribe();
  }

  getProduct(id: number): Observable<Product | undefined> {
    return this.productService.selectById(id);
  }

  getIntervalString(days: number) {
    if (days === 7) {
      return 'wöchentlich';
    }
    if (days === 14) {
      return 'zweiwöchig';
    }
    if (days === 30) {
      return 'monatlich';
    }
    return days + ' tägig';
  }

  clickActionLogin() {
    this.actions.dispatch(authLoginAction());
  }

  clickEdit(sub: ISubscription) {
    this.modalService.open(SubscriptionModalComponent, {
      data: { subscription: sub }
    });
  }

  clickDelete(sub: ISubscription) {
    if (!sub) {
      return;
    }
    this.clickDelete$.next(sub);

  }

  clickRestore(subAction: ISubscriptionAction) {
    this.cartService.removeSubscriptionActionOnIndex(subAction);
  }

  getDeliveryDate(row: ISubscription) {
    let next_order: string | Date | undefined = row.next_order;

    if (row.onCurrentOrder) {
      return formatDate(this.subConfig.getDeliveryDate(null),'dd.MM.yyyy', 'de');
    }
    if (isString(next_order)) {
      next_order = parseISO(next_order);
    }
    if (next_order) {
      return formatDate(this.subConfig.getDeliveryDate(next_order),'dd.MM.yyyy', 'de');
    }
    return;




  }

  getProductLink(product_id: any) {
    return '/product/' + product_id;

  }

  getPrice(row: any) {
   return this.getProduct(row.product_id).pipe(
      map((product) => {
        if (product !== undefined && product.price !== undefined) {
          return (product.price.getAmountGross() * row.quantity).toFixed(2) + " "+ (Utils.getCurrencySymbol(product.price?.currency??'EUR')??'');

        }
        return '-';
      })
    );
  }

  protected readonly Utils = Utils;
  @Input()
  labelHelpText = "Mehr Infos";
}
