import { inject, Injectable } from '@angular/core';
import { CartStore } from './cart.store';

@Injectable({ providedIn: 'root' })
export class CartValidationErrorHandler {
  cartStore = inject(CartStore);

  public handleErrors(errors: any): boolean {
    let changed = false;
    for (const error of errors) {

      if (error.type === 'subscription') {
        this.cartStore.removeSubscriptionChangeWithId(error.referenceId);
        changed = true;
      }

      if (error.type === 'subscription_line') {
        this.cartStore.removeSubscriptionLineChangeWithId(error.referenceId);

        changed = true;
      }
      if (error.type == 'product') {
        this.cartStore.removeProductChangeWithId(error.referenceId);
        changed = true;
      }
    }
    return changed;
  }
}
