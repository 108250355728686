<header>
  <div><h1>PISCI Admin</h1></div>
  <div (click)="clickLogout()">logout</div>
</header>

<div class="main">
<app-menu></app-menu>
  <main><router-outlet></router-outlet></main>
</div>
<div class="loading" *ngIf="loadingService.loading$ | async">
  <mat-spinner></mat-spinner>
</div>
