<div class="ui-widget">
  <h3>Deine Abos</h3>
  <app-expandable [label]="labelHelpText">
  <ng-content></ng-content>
  </app-expandable>

  <div class="ui-mini-cart-widget subscription">
    <ng-container *ngIf="!(isLoggedIn$ | async); else elseBlock">
      Du bist nicht eingeloggt.
      <button (click)="clickActionLogin()">Einloggen</button>
    </ng-container>
    <ng-template #elseBlock>
      <ng-container
        *ngIf="dataSourceSubscription$ | async as dataSourceSubscription"
      >
        <div>
          <table class="ui-mini-cart-table cart-table">
            <thead>
              <th></th>
              <th>Name</th>
              <th>Menge</th>
              <th>Intervall</th>
              <th>Nächstes Lieferdatum</th>
              <th>Preis</th>
            </thead>
            <tr *ngFor="let row of dataSourceSubscription">
              <td>
                <ng-container *ngIf="!disableActions">
                <button mat-raised-button color="warn" (click)="clickDelete(row)"><mat-icon>delete</mat-icon></button>
                <button mat-raised-button (click)="clickEdit(row)">
                  <mat-icon>edit</mat-icon>
                </button>
                </ng-container>
              </td>
              <td  data-label="Produkt" class="product">
               <a [routerLink]="getProductLink(row.product_id)">{{ (getProduct(row.product_id) | async)?.name }}</a>
              </td>
              <td data-label="Menge" class="quantity">
                {{ row.quantity }}
              </td>
              <td data-label="Intervall" class="interval">
                {{ getIntervalString(row.interval_days) }}
              </td>
              <td data-label="Nächste Lieferung" class="next_delivery">
                {{ getDeliveryDate(row)  }}
              </td>
              <td class="price"  data-label="Preis">
                {{ getPrice(row)|async }}

              </td>
            </tr>
          </table>
        </div>
        <ng-container *ngIf="!disableActions">
          <div *ngIf="subRemoves$ | async as removes">
          <ng-container *ngIf="removes.length > 0">
            <h4>Zu löschende Abos</h4>
            <table class="ui-mini-cart-table cart-table">
              <thead>
                <th></th>
                <th>Name</th>
                <th>Menge</th>
                <th>Intervall</th>
                <th>Nächstes Lieferdatum</th>
                <th>Preis</th>
              </thead>
              <tbody>
                <tr *ngFor="let row of removes">
                  <td>
                    <button (click)="clickRestore(row)">
                      <mat-icon>settings_backup_restore</mat-icon>
                    </button>
                  </td>
                  <ng-container *ngIf="row.subscription as subscription">
                    <td>
                      {{ (getProduct(subscription.product_id) | async)?.name }}
                    </td>
                    <td  data-label="Menge">
                      {{ subscription.quantity }}
                    </td>
                    <td data-label="Interval">
                      {{ getIntervalString(subscription.interval_days) }}
                    </td>
                    <td data-label="Nächste Bestellung">
                      {{ subscription.next_order | date: 'dd.MM.yyyy' }}
                    </td>
                    <td class="price">
                      {{
                        (
                          getProduct(subscription.product_id) | async
                        )?.price?.toString()
                      }}
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
        </ng-container>
        <div *ngIf="dataSourceSubscription.length == 0">
          Du hast noch keine Abo's abgeschlossen.
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>
