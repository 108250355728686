import {
  Component,
  EventEmitter, inject,
  Input,
  NgZone,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  FormControlStatus,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ConfirmPasswordValidator } from '@app/lib-confirm-password';
import {
  combineLatest,
  delay,
  filter,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { Checkout2Service } from '../checkout2.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnimationKeyframesSequenceMetadata } from '@angular/animations';
import {
  addressesChanged,
  CartService,
  dataChanged,
} from '@app/shared/data-cart2';
import { emitOnce } from '@ngneat/elf';
import { Actions } from '@ngneat/effects-ng';
import { ofType } from '@ngneat/effects';
import { isString } from 'lodash';
@UntilDestroy()
@Component({
  selector: 'checkout2',
  templateUrl: './checkout2.component.html',
  styleUrls: ['./checkout2.component.scss'],
})
export class Checkout2Component implements OnInit {
  private fb = inject(UntypedFormBuilder);
  @Input()
  mode = 'action';
  @Input()
  flowControl?: Observable<string>;
  @Output()
  formChanged = new EventEmitter();
  form = this.fb.group({
    payment_method: this.fb.control(''),
    shipment_options: this.fb.control(''),

    billing_address: this.getAddressForm(),
    shipping_address: this.getAddressForm(),
    data: this.fb.group({
      phone: this.fb.control('', Validators.required),
    }),
    mode: this.fb.control('once'),
    customer_note: this.fb.control(''),
    sameAddress: this.fb.control(false),
  });

  shippingEqualsInvoice = true;
  paymentMethods$;
  shipmentOptions$;
  @Input()
  allowedZips: string[] = [];

  private getAddressForm() {
    return this.fb.group({
      firstname: this.fb.control(''),
      lastname: this.fb.control(''),
      name: this.fb.control(''),
      street: this.fb.control('', Validators.required),
      street_number: this.fb.control('', Validators.required),
      street_2: this.fb.control(''),
      zip: this.fb.control('', Validators.required),
      city: this.fb.control('', Validators.required),
      country: this.fb.control('AT'),
    });
  }
  constructor(
    public checkoutService: Checkout2Service,
    private cart: CartService,
    private actions: Actions,
    private zone: NgZone
  ) {
    this.form.statusChanges.subscribe((status: FormControlStatus) => {
      this.checkoutService.isValid = status == 'VALID';
    });
    this.form.valueChanges.subscribe((form) => {
      this.formChanged.next(form);
    });

    this.checkoutService.loadPaymentMethods();
    this.checkoutService.loadShipmentOptions();
    this.shipmentOptions$ = this.checkoutService.selectShipmentOptions();
    this.paymentMethods$ = this.checkoutService.selectPaymentMethods();

    let addresses = {
      shipping_address: this.cart.getShippingAddress(),
      billing_address: this.cart.getBillingAddress(),
    };
    this.form.patchValue(addresses, { emitEvent: false });
    this.initShippingEqualsPipe();
    this.initUpdatesOnChanges();
    this.initValues();
    console.log(this.form);
  }

  initValues() {
    combineLatest([
      this.checkoutService.selectShipmentOptions(),
      this.checkoutService.selectPaymentMethods(),
    ])
      .pipe(
        filter(([shipping, payments]) => {
          return shipping.length > 0 && payments.length > 0;
        }),
        take(1),
        tap(([shipping, payments]) => {
          this.form.patchValue({
            payment_method: payments[0].method_key,
            shipment_options: shipping[0].id,
          });
        })
      )
      .subscribe();
  }

  isRequiredField(field: string) {
    const form_field = this.form.get(field);
    if (!form_field?.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return validator && validator['required'];
  }

  getErrorMessage(controlName: string) {
    return this.form.get(controlName)?.errors;
  }

  hasErrors() {
    return this.form.touched && !this.form.valid;
  }

  getErrors(): { [key: string]: any } {
    let errors = {};
    this.aggregateErrors(this.form, errors);
    return errors;
  }

  public aggregateErrors(
    form: UntypedFormGroup | UntypedFormArray,
    errors: ValidationErrors
  ): void {
    Object.keys(form.controls).forEach((key: string) => {
      const abstractControl = form.get(key);

      if (
        abstractControl instanceof UntypedFormGroup ||
        abstractControl instanceof UntypedFormArray
      ) {
        this.aggregateErrors(abstractControl, errors);
      }

      if (
        abstractControl instanceof UntypedFormControl &&
        abstractControl.errors
      ) {
        errors[key] = abstractControl.errors;
      }
    });
  }

  initShippingEqualsPipe() {
    this.form.valueChanges
      .pipe(
        filter(() => this.shippingEqualsInvoice),
        tap((form) => {
          console.log(form);
          this.form.patchValue(
            {
              shipping_address: form.billing_address,
            },
            { emitEvent: false }
          );
          return of(true);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  initUpdatesOnChanges() {
    this.actions
      .pipe(
        ofType(addressesChanged),
        tap((action) => {
          if (action.sameAddress) {
            this.form.patchValue({ sameAddress: action.sameAddress });
          }
          this.form.patchValue(
            {
              billing_address: action.billingAddress,
              shipping_address: action.shippingAddress,
            },
            { emitEvent: false }
          );
        }),
        untilDestroyed(this)
      )
      .subscribe();
    this.actions
      .pipe(
        ofType(dataChanged),
        tap((action) => {
          if (action.data) {
            this.form.patchValue({ data: action.data });
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
    combineLatest([this.form.valueChanges, this.shipmentOptions$])
      .pipe(
        tap(([form, options]) => {
          let value = form.shipment_options;
          if (typeof value === 'number' && options) {
            let found = options.find((x) => x.id == value);
            this.checkoutService.shipmentOptionSelected(found);
          } else {
            this.checkoutService.shipmentOptionSelected(value);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.form.valueChanges
      .pipe(
        tap((value) => {
          this.shippingEqualsInvoice = value.sameAddress;
          if (this.form.valid) {
            emitOnce(() => {
              this.cart.cartSameAddress(value.sameAddress);
              if (this.shippingEqualsInvoice) {
                this.cart.setAddresses(
                  value.billing_address,
                  value.billing_address
                );
              } else {
                this.cart.setAddresses(
                  value.billing_address,
                  value.shipping_address
                );
              }
              if (value.payment_method) {
                this.cart.setPaymentMethod(value.payment_method);
              }
              if (value.shipment_options) {
                this.cart.setShipmentOption(value.shipment_options);
              }
              if (value.customer_note) {
                this.cart.setCustomerNote(value.customer_note);
              }

              if (value.data.phone) {
                this.cart.setDataKey('phone', value.data.phone);
              }
            });
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
  ngOnInit(): void {
    if (this.cart.zipCodes.length > 0) {
      this.form
        .get('shipping_address.zip')
        ?.addValidators(this.zipCodeValidator(this.cart.zipCodes));
    }
    if (this.flowControl) {
      this.flowControl
        .pipe(
          tap(console.log),
          filter((value) => value == 'wantsToBuy'),
          tap(() => {
            this.form.markAsTouched();
          })
        )
        .subscribe();
    }
  }

  showErrors() {
    return this.checkoutService.showErrors;
  }
  zipCodeValidator(zipCodes: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let found = false;
      for (let code of zipCodes) {
        if (!isString(control.value)) return null;
        if ((control.value as String).startsWith(code)) {
          found = true;
        }
      }
      if (found) {
        return null;
      }
      return { zipCodeNotAllowed: true };
    };
  }
}
