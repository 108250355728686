import { AppConfig } from '@pisci/requestManager';
import { environment } from '../environments/environment';

let config;
if (localStorage.getItem('admin_base_url')) {
  config = {
    base_url: localStorage.getItem('admin_base_url') ?? ''
  };
} else if (environment.production) {
  config = {
    base_url: 'https://api.logistikmanager.pisci.eu/api',
  };

} else  if (window.location.hostname.startsWith('staging')) {
  config = {
    base_url: 'https://api.staging.logistikmanager.pisci.eu/api',
  };
} else {
  config = {
    base_url: 'https://api.dev.logistikmanager.pisci.eu/api',
  };
}

export const DB_CONFIG: AppConfig = config;
