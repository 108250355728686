import { Component, inject } from '@angular/core';
import { AuthService } from '@pisci/requestManager';
import { combineLatest, EMPTY, map, Observable, of } from 'rxjs';
import { filterNil, isString } from '@ngneat/elf';
import { CartService, SubscriptionService } from '@app/shared/data-cart2';
import { ProductService } from '@app/shared/data-products';
import { ISubscription, ISubscriptionAction } from '@app/data-interfaces';
import { tap } from 'rxjs/operators';
import { parseISO } from 'date-fns';
import { SubscriptionConfigService } from '@cw-addtocart';
import * as _ from 'lodash';
import { formatDate } from '@angular/common';
import { Utils } from '@app/utils';

@Component({
  selector: 'app-subscription-changes-list',
  templateUrl: './subscription-changes-list.component.html',
  styleUrls: ['./subscription-changes-list.component.scss']
})
export class SubscriptionChangesListComponent {
  authService = inject(AuthService);
  cartService = inject(CartService);
  productService = inject(ProductService);
  subscriptionService = inject(SubscriptionService);
  subConfig = inject(SubscriptionConfigService);
  dataSourceSubscription$:Observable<{change: ISubscriptionAction, subscription: ISubscription|undefined }[]> = combineLatest([this.subscriptionService.selectSubscriptions(), this.cartService.selectSubscriptionChanges()]).pipe(
    map(([subscriptions, changes]) => {
      return changes.map((change) => {
        const subscription = subscriptions.find((sub) => sub.id === change.subscription.id);
        return { change,subscription
    }})}),
    tap(console.log),
    filterNil()
  );
  isLoggedIn$ = this.authService.selectIsLoggedIn();
  disableActions = false;

  clickDelete(row: any) {

  }

  clickEdit(row: any) {

  }

  getProductLink(product_id: any) {
    return "";
  }

  getProduct(product_id: any):Observable<any> {
    return this.productService.selectById(product_id);
  }

  getIntervalString(days: any) {
    if (days === 7) {
      return 'wöchentlich';
    }
    if (days === 14) {
      return 'zweiwöchig';
    }
    if (days === 30) {
      return 'monatlich';
    }
    return days + ' tägig';
  }

  getDeliveryDate(row: any): string {
    let next_order: string | Date | undefined = row.next_order;

    if (row.onCurrentOrder) {

      return formatDate(this.subConfig.getDeliveryDate(null),'dd.MM.yyyy', 'de');
    }
    if (isString(next_order)) {
      next_order = parseISO(next_order);
    }
    if (next_order) {
      return formatDate(this.subConfig.getDeliveryDate(next_order),'dd.MM.yyyy', 'de');
    }
    return '';

  }

  clickRestore(subAction: any) {
    this.cartService.removeSubscriptionActionOnIndex(subAction);

  }

  isDifferent(row:any, field: string) {
    if(field=="quantity")  {
      return row.change.subscription.quantity - row.subscription.quantity != 0;
    }

    return row.change.subscription[field] != row.subscription[field];
  }

  getPrice(change: ISubscriptionAction) {
    return this.getProduct(change.subscription.product_id).pipe(
      map((product) => {
        if(!product) {
          return 0;
        }
        return product.price.getAmountGross() * (change.subscription.quantity??0);
      })
    );

  }

  protected readonly Utils = Utils;
}
