<div class="ui-widget">
  <h3>Deine Abo Änderungen</h3>
  <div class="ui-mini-cart-widget subscription">
    <ng-container *ngIf="isLoggedIn$|async">
      <ng-container
        *ngIf="dataSourceSubscription$ | async as dataSourceSubscription"
      >
        <div>
          <table class="ui-mini-cart-table cart-table">
            <thead>
            <th></th>
            <th>Name</th>
            <th>Menge</th>
            <th>Intervall</th>
            <th>Nächstes Lieferdatum</th>
            <th>Preis</th>
            </thead>
            <tr *ngFor="let row of dataSourceSubscription">
              <td>
                <ng-container *ngIf="!disableActions">
                  <button (click)="clickRestore(row.change)">
                    <mat-icon>settings_backup_restore</mat-icon>
                  </button>
                  <div class="no-button"></div>

                </ng-container>
              </td>
              <td data-label="Produkt" class="product">
                <a [routerLink]="getProductLink(row.change.subscription.product_id)">{{ (getProduct(row.change.subscription.product_id) | async)?.name }}</a>
              </td>
              <td data-label="Menge" class="quantity">
                <div>{{ row.change.subscription.quantity }}</div>
                <div class="old" *ngIf="row.subscription && isDifferent(row,'quantity')" aria-label="Old quantity">{{row.subscription.quantity}}</div>
              </td>
              <td data-label="Intervall" class="interval">
                <div>{{ getIntervalString(row.change.subscription.interval_days) }}</div>
                <div class="old" *ngIf="row.subscription && isDifferent(row,'interval_days')" aria-label="Old interval">{{getIntervalString(row.subscription.interval_days)}}</div>
              </td>
              <td data-label="Nächste Lieferung" class="next_delivery">
               <div> {{ getDeliveryDate(row.change.subscription)  }}</div>
                <div class="old" *ngIf="row.subscription && isDifferent(row,'next_order')" aria-label="Old delivery date">{{getDeliveryDate(row.subscription) | date: 'dd.MM.yyyy'}}</div>
              </td>
              <td class="price"  data-label="Preis" class="price">
                {{ getPrice( row.change ) | async | number :'1.2-2'}}&nbsp;{{Utils.getCurrencySymbol(row.change.subscription.price?.currency??'EUR')}}
              </td>

            </tr>

          </table>
        </div>

        <div *ngIf="dataSourceSubscription.length == 0">
         Keine Änderungen
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
