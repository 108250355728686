<div class="cw-modal" [formGroup]="intervalForm">
  <div class="cw-modal-header">
    <h4 class="title">{{ title }}</h4>

    <button matButton (click)="clickClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <span>Produkt:</span><span class="product" *ngIf="product|async as p">{{ p.name }}</span
><br/>
  <label for="subInterval"> Lieferintervall </label>
  <select matSelect formControlName="interval_days" id="subInterval">
    <ng-container *ngFor="let option of config.interval_options">
      <option [value]="option.interval_days">{{ option.label }}</option>
    </ng-container>
  </select>

  <label for="subAmount">Menge</label>
  <app-number-button-input
    id="subAmount"
    formControlName="quantity"
  ></app-number-button-input>
  <label> Startdatum: </label>
  <div class="beginDateWrapper">
    <ng-container *ngIf="config.showDatePicker">



        <input matInput [matDatepicker]="picker" readonly (click)="picker.open()" formControlName="startingFrom">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>

    </ng-container>
    <ng-container *ngIf="!config.showDatePicker">
      <mat-select formControlName="startingFrom">
        <mat-option *ngFor="let date of getPossibleDates(); let index = index" [value]="getDateValueForForm(date)">
          <ng-container *ngIf="config.possibleDatesLabels && config.possibleDatesLabels[index] ?? null">
            {{ config.possibleDatesLabels }}
          </ng-container>
          <ng-container *ngIf="!config.possibleDatesLabels || !config.possibleDatesLabels[index] ?? null">
            {{ getDeliveryDate(date)|date:'EEE dd.MM.yyyy' }}
          </ng-container>
        </mat-option>
      </mat-select>
    </ng-container>
  </div>
  <button matButton (click)="clickAddUpdateSubscription()">{{ title }}</button>
  <button *ngIf="hasDeleteButton()" matButton class="delete" (click)="clickDeleteSubscription()">
    <mat-icon>delete</mat-icon>
  </button>
</div>
