import { Component, inject, OnInit } from '@angular/core';
import { RequestService } from '@pisci/requestManager';
import { FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SettingsvalueService } from '@cw/data-settings';
import { debounceTime, merge, take } from 'rxjs';


import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

const KEY_NOTIFICATION = 'notification_subscription';


@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {
  rm = inject(RequestService);
  settingsValueService = inject(SettingsvalueService);
  toast = inject(MatSnackBar);
subscriptionNotification = new UntypedFormGroup( {
greeting:new UntypedFormControl(""),
footer:new UntypedFormControl,
signature:new UntypedFormControl}
);
toastOpen = false;
  ngOnInit(): void {
    this.settingsValueService.loadKey(KEY_NOTIFICATION).subscribe();
    this.settingsValueService.selectKey(KEY_NOTIFICATION).pipe(take(1)).subscribe((settings: any) => {
      this.subscriptionNotification.patchValue(settings.data);
    });
    this.settingsValueService.loadKey("emails").subscribe();
    this.settingsValueService.selectKey("emails").pipe(take(1)).subscribe((settings: any) => {
      this.emails.patchValue(settings.data);
    });
    }


  protected readonly editor = ClassicEditor;
  emails: FormGroup = new FormGroup( {
    order_created_text: new UntypedFormControl(""),
  });

  save() {
    if (this.toastOpen) return;

    const saveNotification$ = this.settingsValueService.saveSetting(KEY_NOTIFICATION, this.subscriptionNotification.value);
    const saveEmails$ = this.settingsValueService.saveSetting("emails", this.emails.value);

    merge(saveNotification$, saveEmails$).pipe(debounceTime(200)).subscribe(() => {
      this.settingsValueService.loadKey(KEY_NOTIFICATION).subscribe();
      this.settingsValueService.loadKey("emails").subscribe();
      this.showToast('Saved');
    });
  }

  private showToast(message: string) {
    this.toastOpen = true;
    this.toast.open(message, 'Close', {
      duration: 2000
    }).afterDismissed().subscribe(() => {
      this.toastOpen = false;
    });
  }
}
