<div class="header" (click)="clickExpand()"><p class="label" >{{label}}</p>
<mat-icon class="icon" *ngIf="isExpanded()">expand_less</mat-icon>

  <mat-icon class="icon" *ngIf="!isExpanded()">expand_more</mat-icon>
</div>
<div class="expandable" [ngClass]="{'expanded' :isExpanded()}">

<ng-content></ng-content>
</div>


