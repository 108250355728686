import { isString } from '@ngneat/elf';
import { isArray, isNumber } from 'lodash';
import { ISubscription } from './cart';
import { Utils } from '@app/utils';

import { parseISO } from 'date-fns'

export interface IPrice {
  id?: number;
  amount_net: string;
  amount_gross: string;
  amountGross?: number;
  amountNet?: number;
  currency: string;
  tax_total: string;
  taxes: ITax[];
}

export interface ITax {
  id?: number;
  tax: string | number;
  amountNet?: number;
  taxgroups_id: number;
  taxgroup: ITaxgroup;
  amount_net?: string;
}

export interface ITaxgroup {
  id?: number;
  tax: number | string;
  name?: string;
  label: string;
}

export class Tax implements ITax {
  amountAsNumber?: number;
  private taxObject: ITax;

  constructor(data: ITax | ITaxDTO) {
    if (isOfTypeITax(data)) {
      this.taxObject = data;
      if (data.tax && isString(data.tax)) {
        this.amountAsNumber = Number.parseFloat(data.tax);
      } else if (isNumber(data.tax)) {
        this.amountAsNumber = data.tax;
      }
      return;
    } else if (isOfTypeITaxDTO(data)) {
      this.taxObject = {
        id: data.taxgroups_id,
        tax: data.taxrate,
        amountNet: data.amountNet,
        taxgroups_id: data.taxgroups_id,
        taxgroup: {
          id: data.taxgroups_id,
          tax: data.taxrate,
          label: data.name,
        },
      };
      this.amountAsNumber = data.amountGross;
      return;
    }
    throw new Error('Tax is not of type ITax or ITaxDTO');
  }

  public get id(): number | undefined {
    return this.taxObject.id;
  }

  public get tax(): string | number {
    return this.taxObject.tax;
  }

  public get amountNet(): number | undefined {
    return this.taxObject.amountNet;
  }

  public get taxgroups_id(): number {
    return this.taxObject.taxgroups_id;
  }

  public get taxgroup(): ITaxgroup {
    return this.taxObject.taxgroup;
  }

  public get amount_net(): string | undefined {
    return this.taxObject.amount_net;
  }

  getAmountNet(): number {
    if (this.taxObject.amountNet) {
      return this.taxObject.amountNet;
    } else {
      return Number.parseFloat(this.taxObject.amount_net ?? '0');
    }
  }

  getAmount(): number {
    if (this.amountAsNumber) {
      return this.amountAsNumber;
    }
    return 0;
  }
}

export function isOfTypeIPriceDTO(iprice: unknown): iprice is IPriceDTO {
  return isNumber((iprice as IPriceDTO).amountGross);
}

export function isOfTypeIPrice(iprice: unknown): iprice is IPrice {
  return (iprice as IPrice).amount_gross !== undefined;
}

export function isOfTypeITaxDTO(data: unknown): data is ITaxDTO {
  return (data as ITaxDTO).taxgroups_id !== undefined;
}

export function isOfTypeITax(data: unknown): data is ITax {
  return (data as ITax).taxgroup !== undefined;
}

export class Price implements IPrice {
  id?: number;
  amount_net: string;
  amount_gross: string;
  amountGross?: number;
  currency: string;
  amountNet?: number;
  tax_total: string;
  taxes: Tax[];

  constructor(iprice: unknown) {
    if (isOfTypeIPriceDTO(iprice)) {
      this.amountGross = iprice.amountGross;
      this.amountNet = iprice.amountNet;
      this.amount_net = iprice.amountNet.toString();
      this.amount_gross = iprice.amountGross.toString();
      this.currency = 'EUR';
      this.tax_total = '0';
      this.taxes = iprice.taxes.map((tax) => new Tax(tax));
      return;
    }
    if (isOfTypeIPrice(iprice)) {
      this.id = iprice.id;
      this.amount_net = iprice.amount_net;
      this.amount_gross = iprice.amount_gross;
      this.amountGross = Number.parseFloat(iprice.amount_gross);
      this.amountNet = Number.parseFloat(iprice.amount_net);
      this.currency = iprice.currency;
      this.tax_total = iprice.tax_total;
      this.taxes = iprice.taxes.map((tax) => new Tax(tax));
      return;
    }
    throw new Error('Price is not of type IPrice or IPriceDTO');
  }

  getAmountGross() {
    if (this.amountGross) {
      return this.amountGross;
    } else {
      return Number.parseFloat(this.amount_gross);
    }
  }

  getAmountNet() {
    if (this.amountNet) {
      return this.amountNet;
    } else {
      return Number.parseFloat(this.amount_net);
    }
  }

  getTotalAmountGross(quantity: number) {
    return this.getAmountGross() * quantity;
  }

  toString() {
    return this.getAmountGross().toFixed(2) + ' €';
  }
}

export interface IPriceDTO {
  amountGross: number;
  amountNet: number;
  taxes: ITaxDTO[];
}

export interface ITaxDTO {
  name: string;
  amount: number;
  amountGross: number;
  amountNet: number;
  price_id: number;
  taxrate: string;
  taxgroups_id: number;
  taxgroup?:ITaxgroup;
}

export interface ILineItemDTO {
  name: string;
  price: IPriceDTO;
  quantity: number;
  type: string;
  product? :number;
}

export interface ISubscriptionLineItemDTO extends ILineItemDTO {
  id: string | number;
  subscription_id?: string | number,
  uuid?: string;
}

export interface IOrderDTO {
  lines: ILineItemDTO[];
  voucherLines: ILineItemDTO[];
  total: IPriceDTO;
  shippingLine: ILineItemDTO;
  subtotal: IPriceDTO;
  subscriptions: ISubscription[];
  errors?: ValidationError[]
}

export interface ValidationError {
  type:string,
  referenceId:string,
  data:any,
  message:string
}

export class LineItemDTO {
  name: string;
  price: PriceDTO;
  quantity: number;
  type: string;
  properties: any;
  product?: number;

  constructor(line: ILineItemDTO) {
    this.properties = { ...line };
    this.name = line.name;
    this.price = new PriceDTO(line.price);
    this.quantity = line.quantity;
    this.type = line.type;
    if(line.product) {
      this.product = line.product;
    }

  }
}


export class SubscriptionLineItemDTO extends LineItemDTO {
  subscription_id?: string | number;
  id?: number | string;
  uuid?: string;

  constructor(line: ISubscriptionLineItemDTO) {
    super(line);
    if (line.id) {
      this.id = line.id;
    }
    if (line.uuid) {
      this.uuid = line.uuid;
    }
    if (line.subscription_id) {
      this.subscription_id = line.subscription_id;
    }

  }
}

class Priceable {
  constructor(
    public amountGross: number,
    public amountNet: number,
  ) {
  }

  getAmountGross(): number {
    return this.amountGross;
  }

  getAmountNet(): number {
    return this.amountNet;
  }
}

export class PriceDTO extends Priceable {
  taxes: TaxDTO[];

  constructor(price: IPriceDTO) {
    super(price.amountGross, price.amountNet);
    this.taxes = price.taxes.map((tax) => new TaxDTO(tax));
  }

  public hasTaxes(): boolean {
    return this.taxes.length > 0;
  }
}

export class TaxDTO extends Priceable {
  name: string;
  amount: number;
  price_id: number;
  taxrate: string;
  taxgroups_id: number;
  taxgroup?: ITaxgroup;

  constructor(tax: ITaxDTO) {
    super(tax.amountGross, tax.amountNet);
    this.name = tax.name;
    this.amount = tax.amount;
    if(tax.taxgroup) {
      this.taxgroup = tax.taxgroup;
    }
    this.taxgroups_id = tax.taxgroups_id;
    this.taxrate = tax.taxrate;
    this.price_id = tax.price_id;
  }

  getAmount() {
    return this.amount;
  }
}

export class Subscription implements  ISubscription{
  customer_id?: number;
  id: number;
  interval_days: number;
  next_order: Date | undefined;
  price_id?: number;
  product_id: number;
  quantity: number;
  uuid?: string;
  onCurrentOrder? :boolean

  constructor(subscription: ISubscription) {
    this.customer_id = subscription.customer_id;
    this.id = subscription.id;
    this.interval_days = Utils.getNumber(subscription.interval_days);
    if(isString(subscription.next_order)) {
      this.next_order = parseISO(subscription.next_order);
    } else {
      this.next_order = subscription.next_order;
    }
    this.price_id = subscription.price_id;
    this.product_id = subscription.product_id;
    this.quantity = subscription.quantity;
    this.onCurrentOrder = subscription.onCurrentOrder;
    this.uuid = subscription.uuid;
  }
}

export class OrderDTO {
  lines: LineItemDTO[];
  voucherLines: LineItemDTO[] = [];
  shippingLine?: LineItemDTO;
  total: PriceDTO;
  subtotal: PriceDTO;
  subscriptions: Subscription[] = [];
  subscriptionLines: SubscriptionLineItemDTO[] = [];

 public  hasErrors():boolean {
    return (this.order?.errors && this.order.errors.length > 0) ?? false;
  }

  constructor(private order: IOrderDTO) {
    this.lines = order.lines.filter(line => line.type !== 'subscription_line').map((line) => new LineItemDTO(line));
    this.subscriptionLines = order.lines.filter(line => line.type === 'subscription_line').map(
      (line) => new SubscriptionLineItemDTO((line as ISubscriptionLineItemDTO))
    );
    this.subtotal = new PriceDTO(order.subtotal);
    if (order.shippingLine) {
      this.shippingLine = new LineItemDTO(order.shippingLine);
    }
    if (order.voucherLines) {
      this.voucherLines = order.voucherLines.map(
        (line) => new LineItemDTO(line),
      );
    }
    if (order.subscriptions) {
      if (!isArray(order.subscriptions)) {
        order.subscriptions = Object.values(order.subscriptions);
      }
      this.subscriptions = order.subscriptions.map(subs => new Subscription(subs))
    }

    this.total = new PriceDTO(order.total);
  }

  getAllLines() {
    return [...this.lines.filter(line => line.type !== "shipping"), ...this.subscriptionLines, ...this.voucherLines, ...this.lines.filter(line => line.type == "shipping")];
  }

  getErrors() {
    return this.order.errors;
  }
}
