import { inject, Injectable } from '@angular/core';

import {
  IAddress,
  ICart,
  ICartLine,
  ILineOptions,
  IOrderDTO,
  IProduct,
  ISubscriptionAction,
  ISubscriptionLineItemDTO,
  OrderDTO,
  Price
} from '@app/data-interfaces';
import { Utils } from '@app/utils';
import { AuthService, RequestService } from '@pisci/requestManager';
import { cloneDeep, isEqual, isNumber, pick } from 'lodash';
import { combineLatest, EMPTY, Observable, of, startWith, Subject, withLatestFrom } from 'rxjs';
import {
  auditTime,
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  map,
  mergeMap,
  switchMap,
  take,
  tap
} from 'rxjs/operators';

import { dispatch, ofType } from '@ngneat/effects';
import { Actions } from '@ngneat/effects-ng';
import { emitOnce, filterNil, isString, select, setProp, setProps } from '@ngneat/elf';
import {
  addEntities,
  deleteAllEntities,
  deleteEntities,
  getAllEntities,
  getEntitiesCount,
  selectAllEntities,
  selectEntitiesCount,
  selectEntity,
  updateEntities
} from '@ngneat/elf-entities';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { v4 as uuidv4 } from 'uuid';
import {
  addressesChanged,
  dataChanged,
  proposeDefaultAddress,
  proposePhoneNumber,
  subscriptionChanged,
  updatedItems
} from './cart.action';
import {
  CartStore,
  cartStore, subscriptionLineChangesEntitiesRef,
  subscriptionLineEntitiesRef,
  subscriptionsEntitiesRef
} from './cart.store';
import { CartValidationErrorHandler } from './CartValidationErrorHandler';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class CartService {
  store = inject(CartStore);
  zipCodes: string[] = [];
  beforeAdd$ = new Subject();
  afterAdd$ = new Subject();

  requiredFields: string[] = [];

  refreshCart$ = new Subject();

  constructor(
    private rm: RequestService,
    private actions: Actions,
    private authService: AuthService,
    private cartValidation: CartValidationErrorHandler
  ) {
    // TODO Make this code returning only one value in a map function and update the cartStore in one place

    combineLatest([this.selectCart(), this.authService.selectIsLoggedIn(), this.refreshCart$.pipe(startWith(1)), this.store.selectSubscriptionLineChanges()])
      .pipe(
        debounceTime(0),
        distinctUntilChanged(isEqual),
        withLatestFrom(this.store.selectSubscriptionLineChanges()),
        switchMap(([[cart, isLoggedIn], subLines]) => {
          console.log(cart, isLoggedIn, 'on cart changed');
          if (
            isLoggedIn ||
            (cart.lines && cart.lines.length > 0) ||
            (cart.subscription_changes?.length ?? 0) > 0
            || subLines.length > 0
          ) {
            cart.lines = cart.lines.concat(subLines.map(
              line => (
                {
                  ...line,
                  subscriptionId: line.subscription_id
                }
              )
            ));

            return this.rm
              .post<IOrderDTO>('basket/orderDTODraft', cart)
              .pipe(catchError(() => EMPTY));
          } else {
            return of(null).pipe(delay(2));
          }
        }),

        map((response: null | IOrderDTO) => {

          if (!response) return null;
          response as IOrderDTO;
          if (response.errors && response.errors.length > 0 && cartValidation.handleErrors(response.errors)) {

            (response as any).lines = null;
          }
          if (response.lines) {
            return {
              orderDTO: new OrderDTO(response),
              totalPrice: new Price(response.total)
            };
          } else {
            return {
              orderDTO: undefined,
              totalPrice: {
                amount_gross: '0',
                amount_net: '0',
                amountGross: 0,
                amountNet: 0,
                currency: 'EUR',
                taxes: [],
                tax_total: '0'
              }
            };
          }
        }),
        catchError(() => {
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe((response) => {
        if (response !== null) {
          cartStore.update(
            setProps((state) => {
              return {
                ...state,
                orderDTO: response.orderDTO,
                totalPrice: response.totalPrice
              };
            })
          );
          if (response.orderDTO) {
            const subLines = response.orderDTO.subscriptionLines
              .map((entry) => {
                return {
                  ...entry,
                  id: entry.uuid ?? uuidv4()
                };

              });
            this.store.setSubscriptionLines(subLines);
          }
        }
      });
    this.actions
      .pipe(
        ofType(proposeDefaultAddress),
        tap((action) => {
          this.setDefaultAddresses(action);
        })
      )
      .subscribe();
    this.actions
      .pipe(
        ofType(proposePhoneNumber),
        tap((action) => {
          const data = cartStore.getValue().data ?? {};
          data.phone = action.phone;
          this.setData(data);
        })
      )
      .subscribe();
  }

  requestCartUpdate() {
    this.refreshCart$.next(new Date().getTime());
  }

  setDeliveryDate(date: Date | null | undefined) {
    cartStore.update(setProps({ deliveryDate: date }));
  }

  setAvailableZipCodes(zipCodes: string[]) {
    this.zipCodes = zipCodes;
  }

  setTotalDiscount(discount: number | string) {
    cartStore.update(setProps({ totalDiscount: Utils.getNumber(discount) }));
  }

  selectOrderDTO() {
    return cartStore.pipe(
      select((state) => state.orderDTO),
      filterNil()
    );
  }

  setShipmentOption(shipment_option: any) {
    cartStore.update(setProps({ shipmentOption: shipment_option }));
  }

  setCustomerNote(customer_note: any) {
    cartStore.update(setProps({ customer_note }));
  }

  selectShipmentOption() {
    return cartStore.pipe(select((state) => state.shipmentOption));
  }

  setRequiredFields(required_fields: string[]): void {
    this.requiredFields = required_fields;
  }


  getCalculatedTotalLive() {
    return cartStore.pipe(
      auditTime(0),
      select((cart) => cart.totalPrice)
    );
  }

  addLineItemToCart(
    amountNet: number,
    amountGross: number,
    name: string,
    quantity: number
  ) {
    const lines = cartStore.query(getAllEntities());
    const nextId = cartStore.getValue().latestId + 1;
    cartStore.update(
      addEntities({ id: nextId, amountNet, amountGross, name, quantity }),
      setProps({ latestId: nextId })
    );
    this.emitUpdatedItems();
  }

  addLineToCart(line: ICartLine) {
    const lines = cartStore.query(getAllEntities());
    const nextId = cartStore.getValue().latestId + 1;
    cartStore.update(
      addEntities({ ...line, id: nextId }),
      setProps({ latestId: nextId })
    );
    this.emitUpdatedItems();
  }

  addProductQuantity(product: IProduct, diffQuantity: number) {
    let newQuantity;
    if (isString(diffQuantity)) {
      newQuantity = Number.parseFloat(diffQuantity);
      diffQuantity = newQuantity;
    } else {
      newQuantity = diffQuantity;
    }

    if (product.stock_amount) {
      newQuantity =
        newQuantity < product.stock_amount ? newQuantity : product.stock_amount;
    }
    const lines = cartStore.query(getAllEntities());
    if (product.stackable || product.stackable == undefined) {
      for (const line of lines) {
        if (!isNumber(line.quantity)) {
          line.quantity = Number.parseFloat(line.quantity);
        }
        if (line.productId == product.id && line.id) {
          newQuantity = line.quantity + diffQuantity;
          if (product.stock_amount) {
            newQuantity =
              newQuantity < product.stock_amount
                ? newQuantity
                : product.stock_amount;
          }
        }
      }
      return this.updateProductQuantity(product, newQuantity);
    }

    return this.updateProductQuantity(product, newQuantity);
  }

  getProductQuantityById(productId: number) {
    const lines = cartStore.query(getAllEntities());
    const productLine = lines.find((line) => line.productId == productId);
    return productLine?.quantity ?? 0;
  }

  updateProductQuantity(
    product: IProduct,
    quantity: number,
    options: ILineOptions = {}
  ) {

    const lines = cartStore.query(getAllEntities());
    const highestId = lines.reduce((prev, line: any) => {
      return prev < line.id ? line.id : prev;
    }, 1);
    const localProduct = cloneDeep(product);
    if (!localProduct.price.amountNet || !localProduct.price.amountGross) {
      localProduct.price.amountNet = Number.parseFloat(
        <string>localProduct.price.amount_net
      );
      localProduct.price.amountGross = Number.parseFloat(
        <string>localProduct.price.amount_gross
      );
    }

    if (localProduct.stackable == undefined) {
      localProduct.stackable = true;
    }
    if (localProduct.stackable) {
      for (const line of lines) {
        if (line.productId == localProduct.id && line.id) {
          if (product.stock_amount) {
            quantity =
              quantity < product.stock_amount ? quantity : product.stock_amount;
          }
          const newLine = {
            ...line,
            quantity: quantity
          };
          if (options) {
            this.setOptions(newLine, options);
          }
          if (quantity > 0) {
            cartStore.update(updateEntities(line.id, newLine));
          } else {
            cartStore.update(deleteEntities(line.id));
          }

          this.emitUpdatedItems();
          this.afterAdd$.next(line.id);
          return;
        }
      }
    }

    const nextId = cartStore.getValue().latestId + 1;
    const newLine: any = {
      id: nextId,
      amountNet: localProduct.price.amountNet,
      amountGross: localProduct.price.amountGross,
      name: localProduct.name,
      productId: localProduct.id,
      price: localProduct.price,
      //product: product,
      quantity
    };
    if (options.undeletable) {
      newLine.undeletable = true;
    }
    if (options) {
      this.setOptions(newLine, options);
    }
    if (quantity > 0) {
      cartStore.update(addEntities(newLine, { prepend: options.preferedTop ?? false }), setProps({ latestId: nextId }));
    } else {
      // else if quantity is zero remove from cart
      cartStore.update(deleteEntities(nextId));
    }

    this.emitUpdatedItems();
    this.afterAdd$.next(nextId);
  }

  updateLine(line: ICartLine) {
    if (line.id) {
      cartStore.update(updateEntities(line.id, line));
    }
    this.emitUpdatedItems();
  }

  updateQuantityByLineId(lineId: number, quantity: number) {
    if (isString(quantity)) {
      quantity = Number.parseFloat(quantity);
    }

    cartStore.update(
      updateEntities(lineId, (line: ICartLine) => {
        if (line.product?.stock_amount) {
          quantity = Math.min(quantity, line.product.stock_amount);
        }
        return { ...line, quantity };
      })
    );
    this.emitUpdatedItems();
  }

  setOptions(line: ICartLine, options: ILineOptions) {
    if (options.undeletable) {
      line.undeletable = options.undeletable;
    }
    if (options.subscriptionChangeIndex !== undefined) {
      line.subscriptionChangeIndex = options.subscriptionChangeIndex;
    }
    if (options) {
      line.options = options;
    }
  }

  removeLine(line: ICartLine) {
    cartStore.update(deleteEntities(line.id));
    this.emitUpdatedItems();
  }

  clearCart() {
    emitOnce(() => {
      cartStore.update(deleteAllEntities(), setProp('vouchers', []));

      cartStore.reset();
    });

    this.emitUpdatedItems();

    //  this.cartStore.reset();
  }

  createOrderForCart() {
    return combineLatest([
      this.selectCart(),
      cartStore.pipe(select((state) => state.orderDraft))
    ]).pipe(
      take(1),
      withLatestFrom(this.store.selectSubscriptionLineChanges()),
      mergeMap(([[cart, orderDraft], subLines]): any => {
        if (orderDraft?.id) {
          return of();
        }
        const body: any = cart;
        if (cart && subLines.length > 0)
          body.lines = (cart.lines as any[]).concat(subLines.map(
            line => (
              {
                ...line,
                subscriptionId: line.subscription_id
              }
            )
          )) as any[];

        return this.rm.post('order', cart).pipe(
          tap((order: any) => {
            emitOnce(() => {
              this.resetCart();
              this.clearCart();
            });
            //cartStore.update(setProps({ orderDraft: order}));
          })
        );
      })
    );
  }

  resetCart() {
    cartStore.update(
      setProps({
        shippingAddress: undefined,
        billingAddress: undefined,
        paymentMethod: undefined,
        shippingMethod: undefined,
        subscription_changes: []

      }),
      deleteAllEntities(),
      deleteAllEntities({ ref: subscriptionLineEntitiesRef }),
      deleteAllEntities({ ref: subscriptionsEntitiesRef }),
      deleteAllEntities({ ref: subscriptionLineChangesEntitiesRef })
    );
  }


  getCart(): ICart {
    return {
      lines: cartStore.query(getAllEntities()),
      shippingAddress: cartStore.getValue().shippingAddress,
      paymentMethod: cartStore.getValue().paymentMethod,
      billingAddress: cartStore.getValue().billingAddress
    };
  }

  getCount(): number {
    return cartStore.query(getEntitiesCount());
  }

  setPaymentMethod(paymentMethod: any) {
    cartStore.update(setProps({ paymentMethod: paymentMethod }));
  }

  selectPaymentMethod() {
    return cartStore.pipe(select((state) => state.paymentMethod));
  }

  isValidCart(): Observable<boolean> {
    return this.selectCart().pipe(
      withLatestFrom(this.store.selectSubscriptionLineChanges(), cartStore.pipe(select(state => state.orderDTO))),
      map(([cart, subLines, orderDTO]) => {

        if (cart.lines.length == 0 && cart.subscription_changes?.length == 0 && subLines.length == 0 && (orderDTO === undefined || orderDTO?.lines.length == 0)) {
          return false;
        }
        if (
          this.requiredFields.indexOf('shipping') != -1 &&
          cart.shippingAddress == undefined
        ) {
          return false;
        }
        if (
          this.requiredFields.indexOf('billing') != -1 &&
          cart.billingAddress == undefined
        ) {
          return false;
        }
        if (
          this.requiredFields.indexOf('payment') != -1 &&
          cart.paymentMethod == undefined
        ) {
          return false;
        }
        if (
          this.requiredFields.indexOf('shipmentOption') != -1 &&
          cart.shipmentOption == undefined
        ) {
          return false;
        }
        return true;
      })
    );
  }

  setCustomerId(id: number) {
    cartStore.update(state => ({ ...state, customerId: id }));
  }

  selectCart(): Observable<ICart> {
    return combineLatest([
      this.selectCartLines(),
      cartStore.pipe(select((state) => state.data)),
      cartStore.pipe(select((state) => state.shippingAddress)),
      cartStore.pipe(select((state) => state.billingAddress)),
      cartStore.pipe(select((state) => state.userId)),
      this.selectShipmentOption(),
      this.selectPaymentMethod(),
      cartStore.pipe(select((state) => state.vouchers)),
      cartStore.pipe(select((state) => state.customer_note)),
      cartStore.pipe(select((state) => state.sameAddress)),
      cartStore.pipe(select((state) => state.deliveryDate)),
      cartStore.pipe(select((state) => state.subscription_changes)),
      this.store.selectSubscriptionLineChanges(),
      cartStore.pipe(select((state) => state.customerId))
    ]).pipe(
      //distinctUntilChanged(_.isEqual),
      map(
        ([
           lines,
           data,
           shippingAddress,
           billingAddress,
           userId,
           shipmentOption,
           paymentMethod,
           vouchers,
           customer_note,
           sameAddress,
           deliveryDate,
           subscription_changes,
           subline_changes,
           customer_id

         ]) => {

          const additionalFields: any = {};
          if (userId) {
            additionalFields.userId = userId;
          }
          if (data) {
            additionalFields.data = data;
          }
          if (customer_note) {
            additionalFields.customer_note = customer_note;
          }
          if (customer_id) {
            additionalFields.customer_id = customer_id;
          }
          const mergedLines: any[] = ([] as any[]).concat(lines);

          const body: any = {
            ...additionalFields,
            lines: mergedLines,
            shippingAddress: shippingAddress,
            billingAddress: billingAddress
          };
          if (sameAddress) {
            body.shippingAddress = body.billingAddress;
          }

          if (shipmentOption) {
            body.shipmentOption = shipmentOption;
          }

          if (paymentMethod) {
            body.paymentMethod = paymentMethod;
          }

          if (vouchers) {
            vouchers = vouchers.map((voucher: any) => voucher.code ?? '');
            body.vouchers = vouchers;
          }
          if (deliveryDate) {
            body.date_preferred_delivery = deliveryDate;
          }
          if (subscription_changes) {
            body.subscription_changes = subscription_changes;
          }
          return body;
        }
      ),
      debounceTime(0)
    );
  }

  selectCartLines(): Observable<ICartLine[]> {
    return cartStore.pipe(selectAllEntities());
  }

  selectCount(): Observable<number> {
    return cartStore.pipe(selectEntitiesCount());
  }

  selectCountQuantity(): Observable<number> {
    return cartStore.pipe(
      selectAllEntities(),
      map((entities) =>
        entities.reduce((acc, entity) => {
          return acc + entity.quantity;
        }, 0)
      )
    );
  }

  selectSubscriptionChanges(): Observable<ISubscriptionAction[]> {
    return cartStore.pipe(select((v) => v.subscription_changes));
  }

  setAddresses(
    billingAddress: IAddress,
    shippingAddress: IAddress | undefined = undefined
  ) {
    cartStore.update(setProps({ billingAddress, shippingAddress }));
    this.actions.dispatch(
      addressesChanged({ billingAddress, shippingAddress })
    );
  }

  setDefaultAddresses(addresses: {
    shippingAddress: IAddress | undefined;
    billingAddress: IAddress | undefined;
  }) {
    emitOnce(() => {
      if (
        addresses.shippingAddress &&
        cartStore.getValue().shippingAddress == undefined
      ) {
        cartStore.update(
          setProps({ shippingAddress: addresses.shippingAddress })
        );
      }
      if (
        addresses.billingAddress &&
        cartStore.getValue().billingAddress == undefined
      ) {
        cartStore.update(
          setProps({ billingAddress: addresses.billingAddress })
        );
      }
      let sameAddress = false;
      if (
        (addresses.billingAddress?.id ?? 1) ==
        (addresses.shippingAddress?.id ?? 2)
      ) {
        cartStore.update(setProps({ sameAddress: true }));
        sameAddress = true;
      }
      this.actions.dispatch(
        addressesChanged({
          billingAddress: addresses.billingAddress,
          shippingAddress: addresses.shippingAddress,
          sameAddress: sameAddress
        })
      );
    });
  }

  cartSameAddress(sameAddress: boolean) {
    cartStore.update(setProps({ sameAddress }));
  }

  getBillingAddress() {
    return cartStore.getValue().billingAddress;
  }

  getShippingAddress() {
    return cartStore.getValue().shippingAddress;
  }

  setData(data: any) {
    cartStore.update(setProp('data', (old: any) => ({ ...old, ...data })));
    this.actions.dispatch(dataChanged({ data: data }));
  }

  setDataKey(key: string, value: any) {
    cartStore.update(
      setProp('data', (old: any) => {
        const data = { ...old };
        data[key] = value;

        // this.actions.dispatch(dataChanged({data:data}));
        return data;
      })
    );
  }

  getPaymentUrlForOrder() {
    return cartStore.pipe(
      select((state) => state.orderDraft),

      take(1),
      mergeMap((order) => {
        if (!order) {
          return EMPTY;
        }

        return this.rm.get('/order/' + order['id'] + '/payment').pipe(
          map((res: any) => {
            return res.url;
          })
        );
      })
    );
  }

  setUserId(id: number) {
    cartStore.update(setProps({ userId: id }));
  }

  selectVouchers() {
    return cartStore.pipe(select((state) => state.vouchers));
  }

  removeVoucher(voucher: { id: any }) {
    cartStore.update(
      setProp('vouchers', (vouchers) => {
        const found = vouchers.findIndex((v: any) => v.id == voucher.id);
        vouchers.splice(found, 1);
        return vouchers;
      })
    );
    this.emitUpdatedItems();
  }

  addVoucher(voucherCode: string) {
    return this.rm.get('voucherCheck/' + voucherCode).pipe(
      map((res: any) => {
        cartStore.update(
          setProp('vouchers', (vouchers) => {
            const newV = vouchers;
            if (newV.find((v) => v.id == res.id) != undefined) {
              throw new Error('error duplication');
            }
            newV.push(res);
            return newV;
          })
        );
        this.emitUpdatedItems();
        return res;
      })
    );
  }

  updateSubscriptionLineQuantity(line: ISubscriptionLineItemDTO, quantity: number) {
    this.store.updateSubscriptionLineChanges(line.id, {
      ...line,
      quantity

    });
    this.emitUpdatedItems();
    //cartStore.update();
  }

  selectSubscription(uuid: string) {
    return cartStore.pipe(
      selectEntity(uuid, { ref: subscriptionLineEntitiesRef }),
      mergeMap((entity) => {
        return cartStore.pipe(select((state) => {
            if (entity && entity.subscription_id) {
              return state.orderDTO?.subscriptions.find(sub => sub.id === entity.subscription_id);
            }

            return state.subscription_changes.find(
              (action) => action.subscription.uuid == uuid
            )?.subscription;
          })
        );
      })
    );
  }

  addSubscriptionAction(action: ISubscriptionAction, product: IProduct) {
    if (
      !isNumber(action.subscription.quantity) &&
      action.subscription.quantity
    ) {
      action.subscription.quantity = parseFloat(action.subscription.quantity);
    }
    let uuidOfSubscription: string | undefined;
    if (action.subscription?.uuid) {
      uuidOfSubscription = action.subscription.uuid;
    }
    if (isNumber(action.subscription.quantity)) {
      emitOnce(() => {
        let index: string;
        cartStore.update(
          setProps((state) => {
            let foundSub;
            //check if subscription has id and week in already made subscription
            let foundSubIndex = state.subscriptions.findIndex(v => v.product_id == action.subscription.product_id);
            if (foundSubIndex != -1) {
              foundSub = state.subscriptions[foundSubIndex] ?? null;
              action.subscription.id = foundSub.id;
            }
            if (foundSub == null) {
              foundSubIndex = state.subscription_changes.findIndex(v => v.subscription.product_id == action.subscription.product_id);
              if (foundSubIndex != -1)
                foundSub = state.subscription_changes[foundSubIndex].subscription ?? null;
            }
            let actions = cloneDeep(state.subscription_changes);
            if (uuidOfSubscription) {
              foundSubIndex = actions.findIndex(
                (action: ISubscriptionAction) => action.subscription.uuid == uuidOfSubscription
              );

              index = uuidOfSubscription;
            }
            if (foundSub && foundSub.uuid) {
              index = foundSub.uuid as string;
            }
            if (foundSubIndex != -1) {

              actions.splice(foundSubIndex, 1);
              actions = [...actions];

            }
            if (index == undefined) {
              index = uuidv4();
            }

            if (
              (action.action === 'remove' && action.subscription.id !== null) ||
              action.action === 'add' ||
              action.action == 'update'
            ) {
              if (action.action === 'add' && action.subscription.id) {
                action.action = 'update';
              }
              if (action.action === 'remove') {
                const found = actions.find((a => a.subscription.id === action.subscription.id));
                if (found) {
                  return {
                    ...state,
                    subscription_changes: actions
                  };
                }
                // check if remove action is already on the list
              }

              const subChange: ISubscriptionAction = {
                action: action.action,
                subscription: {
                  ...pick(action.subscription, [
                    'quantity',
                    'id',
                    'product_id',
                    'next_order',
                    'interval_days'
                  ]),
                  uuid: index
                }
              };
              subChange.uuid = index;
              actions = [...actions.concat([subChange])];
            }
            dispatch(subscriptionChanged({ subscriptionAction: action.action }));
            this.emitUpdatedItems();
            return {
              ...state,
              subscription_changes: actions
            };


          })
        );

      });
    }
  }

  removeSubscriptionActionOnIndex(subAction: ISubscriptionAction) {
    if (!subAction.uuid && !subAction.subscription?.id) {
      return;
    }
    emitOnce(() => {
      let found;
      cartStore.update(
        setProp('subscription_changes', (actions) => {
          found = actions.findIndex(
            (line: ISubscriptionAction) =>
              line.uuid === subAction.uuid ||
              line.subscription?.id === subAction.subscription?.id
          );
          actions = cloneDeep(actions);

          actions.splice(found, 1);
          return actions;
        })
      );
    });

    this.emitUpdatedItems();
  }

  selectSubscriptionLines(): Observable<ISubscriptionLineItemDTO[]> {
    return cartStore.pipe(
      selectAllEntities({ ref: subscriptionLineEntitiesRef })
    );
  }

  selectLineItems() {
    return cartStore.pipe(select((state) => state.orderDTO?.getAllLines() ?? []));
  }

  private emitUpdatedItems() {
    this.actions.dispatch(updatedItems());
  }
}
