<div>
  <table class="ui-mini-cart-table cart-table">
    <thead>
    <th></th>
    <th>Name</th>
    <th>Menge</th>
    <th>Interval</th>
    <th>Nächstes Lieferdatum</th>
    <th>Preis</th>
    </thead>
    <tr *ngFor="let row of subscriptions">
      <td>
        <button (click)="clickEdit(row)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
      <td>
        {{ (getProduct(row.product_id))?.name }}
      </td>
      <td>
        {{ row.quantity }}
      </td>
      <td>
        {{ getIntervalString(row.interval_days) }}
      </td>
      <td>
        {{ row.next_order | date: 'dd.MM.yyyy' }}
      </td>
      <td class="price">
        {{ (getProduct(row.product_id))?.price?.toString() }}
      </td>
    </tr>
  </table>
</div>
