import { Injectable } from '@angular/core';
import { IVoucher } from '@app/data-interfaces';
import { RequestService } from '@pisci/requestManager';
import * as _ from 'lodash';
import { switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VoucherService {
  constructor(private rm: RequestService) {}

  getVouchers() {
    return this.rm.getAllPages('/voucher');
  }

  getVoucher(id: string) {
    return this.rm.get('/voucher/' + id);
  }

  upsertVoucher(voucher: Partial<IVoucher>) {
    if (voucher.id) {
      return this.rm.put('/voucher/' + voucher.id, _.omit(voucher, ['id']));
    }
    return this.rm.post('/voucher', voucher);
  }

  deleteVoucher(voucher: Partial<IVoucher>) {
    return this.rm.delete('/voucher/' + voucher.id);
  }

  getTypeLiteral(voucher: IVoucher) {
    switch (voucher.type) {
      case 'value':
        return 'Wertgutschein';
      case 'discount_percent':
        return 'Prozentualer Rabatt';
      case 'discount_value':
        return 'Fixer Rabatt';
      default:
        return 'Nicht erkannter Typ';
    }
  }

  getTypedString(voucher: IVoucher) {
    switch (voucher.type) {
      case 'value':
        return voucher.value + '€ Wertgutschein';
      case 'discount_percent':
        return voucher.value + '% Rabattgutschein';
      case 'discount_value':
        return voucher.value + '€ Rabattgutschein';
      default:
        return 'Nicht erkannter Typ';
    }
  }
}
