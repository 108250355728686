<h3>Dein Einkauf</h3>
<div class="cartWrapper">
  <table class="cartOverview">
    <thead>
      <th class="name">Name</th>
      <th class="quantity">Menge</th>

      <th class="tax">MwSt.</th>
      <th class="price">Preis</th>
    </thead>
    <ng-container *ngIf="orderDTO$ | async as orderDTO">
      <ng-container *ngIf="cartLines$ | async as cartLines">
        <tbody *ngIf="orderDTO">
        <ng-container *ngFor="let element of getLinesOrdered(orderDTO)">
          <ng-container  *ngIf="element.type !== 'subscription_action' && element.quantity !== 0">
          <tr class="from-orderDTO">

            <td class="name">
              {{ element.name }}
            </td>
            <td class="quantity">{{ element.quantity }}</td>

            <td class="tax">
              <span *ngIf="element.price.taxes.length > 0">{{
                element.price.taxes[0] | taxrateTransform
              }}</span>
            </td>
            <td class="price">
              {{ element.price.amountGross | number: '1.2' }} €
            </td>
          </tr>
          <tr *ngFor="let element of orderDTO.voucherLines">
            <td>
              {{ element.name }}
            </td>
            <td>{{ element.quantity }}</td>

            <td>
              <span *ngIf="element.price.taxes.length > 0">{{
                element.price.taxes[0].taxrate
              }}</span>
            </td>
            <td>{{ element.price.amountGross | number: '1.2' }} €</td>
          </tr>
          </ng-container>
      </ng-container>
        </tbody>
        <tbody *ngIf="!orderDTO">
          <tr *ngFor="let element of cartLines">
            <td class="name">
              {{ element.name }}
            </td>
            <td class="quantity">{{ element.quantity }}</td>
            <td class="price">{{ element.amountGross | number: '1.2' }} €</td>
          </tr>
        </tbody>
      </ng-container>
    </ng-container>
  </table>
</div>
