<div>
  <div class="list">
    <ng-container *ngFor="let v of vouchers$ | async">
      <div>{{ v.code }} {{ getType(v) }} {{ v.value | number: '1.2' }}</div>
      <div class="actions">
        <button
          mat-raised-button
          color="primary"
          [routerLink]="['/vouchers', v.id]"
        >
          Bearbeiten
        </button>
      </div>
    </ng-container>
  </div>
  <button mat-raised-button routerLink="/vouchers/new">
    Gutschein hinzufügen
  </button>
</div>
