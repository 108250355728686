import {Injectable} from '@angular/core';
import {ISubscription, Price} from '@app/data-interfaces';
import {createStore, filterNil} from '@ngneat/elf';
import {selectAllEntities, selectEntity, setEntities, withEntities,} from '@ngneat/elf-entities';
import {RequestService} from '@pisci/requestManager';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

export const subStore = createStore(
  {name: 'subscriptions'},
  withEntities<ISubscription>(),
);

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(private rm: RequestService) {
  }

  selectSubscriptions(): Observable<ISubscription[]> {
    return subStore.pipe(
      selectAllEntities(),
      map((subs) => this.transformToClasses(subs)),
    );
  }

  loadAllSubscriptions() {
    return this.rm.getAllPages('subscription').pipe(
      tap((res: any) => {
        subStore.update(setEntities(res, {}));
      }),
    );
  }

  loadMySubscriptions() {
    return this.rm.getAllPages('me/subscriptions').pipe(
      tap((res: any) => {
        subStore.update(setEntities(res, {}));
      }),
    );
  }

  selectSubscription(id: number): Observable<ISubscription> {
    return subStore.pipe(
      selectEntity(id),
      filterNil(),
      map((sub) => this.transformToClass(sub)),
    );
  }

  transformToClasses(subs: ISubscription[]) {
    return subs.map((sub) => this.transformToClass(sub));
  }

  transformToClass(sub: ISubscription) {
    if (!sub.price) {
      return {...sub, price: undefined};
    }
    return {...sub, price: new Price(sub.price)};
  }
}
