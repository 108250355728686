import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '@app/shared/data-products';
import {
  StocksService,
  WarehouseService,
} from '@app/shared/data-stockmanaging';
import { FormlyFieldConfig } from '@ngx-formly/core';
import * as _ from 'lodash';
import { debounceTime, filter, map, mergeMap, of, pipe, startWith } from 'rxjs';

@Component({
  selector: 'app-stocks-edit',
  templateUrl: './stocks-edit.component.html',
  styleUrls: ['./stocks-edit.component.scss'],
})
export class StocksEditComponent implements OnInit {
  stock$;
  productSettings$;
  searchControl = new UntypedFormControl();

  form = new UntypedFormGroup({ search: new UntypedFormControl() });
  formSettings = new UntypedFormGroup({ search: new UntypedFormControl() });
  fieldsSettings: FormlyFieldConfig[] = [
    {
      key: 'product_show_stocks',
      type: 'toggle',
      props: {
        label: 'Zeige Bestand dem Kunden',
      },
    },
    {
      key: 'product_individual_enabled',
      type: 'toggle',

      props: {
        label: 'Lagerverwaltung für dieses Produkt aktivieren.',
      },
    },
    {
      key: 'product_low_threshold',
      type: 'number',

      props: {
        label: 'Schwellwert',
      },
    },
  ];
  fields: FormlyFieldConfig[] = [
    {
      key: 'warehouse_id',
      type: 'select',

      props: {
        options: this.warehouseService.selectWarehouses(),
        label: 'Lager',
        valueProp: 'id',
        labelProp: 'name',
      },
    },
    {
      key: 'product_id',
      type: 'select',

      props: {
        options: this.searchControl.valueChanges.pipe(
          startWith(''),
          debounceTime(1000),
          mergeMap((s: string) => {
            return this.productService.getProductsForAdmin().pipe(
              map((products) =>
                products.filter((p: any) => {
                  if (s === '') return true;
                  if (
                    p.name
                      .toLocaleLowerCase()
                      .indexOf(s.toLocaleLowerCase()) !== -1
                  )
                    return true;
                  return false;
                })
              )
            );
          })
        ),
        label: 'Product',
        valueProp: 'id',
        labelProp: 'name',
      },
    },
    {
      key: 'amount',
      type: 'input',
      templateOptions: {
        label: 'Anzahl',
        required: true,
      },
    },
  ];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private stocks: StocksService,
    private warehouseService: WarehouseService,
    private productService: ProductService
  ) {
    this.productSettings$ = this.route.paramMap.pipe(
      mergeMap((p: any) => {
        return this.productService.getProductForAdmin(p.params.product);
      }),
      map((product: any) => {
        product.data = {
          ...product.data,
          stockmanagement: product.data.stockmanagement ?? {
            product_individual_enabled: null,
            product_low_threshold: null,
            product_show_stocks: null,
          },
        };
        return product;
      })
    );
    this.stock$ = this.route.paramMap.pipe(
      mergeMap((p: any) => {
        if (p.params.product != 'n' && p.params.warehouse != 'n') {
          return this.stocks
            .getCurrent(p.params.product, p.params.warehouse)
            .pipe(
              map((result) =>
                result != null
                  ? result
                  : {
                      warehouse_id: Number.parseInt(p.params.warehouse),
                      product_id: Number.parseInt(p.params.product),
                      amount: 0,
                    }
              )
            );
        }
        return of({ warehouse_id: null, amount: 0 });
      })
    );
    this.form.valueChanges.subscribe((data) => {
      if (
        this.form.get('warehouse_id') !== data.warehouse_id ||
        this.form.get('product_id') !== data.product_id
      ) {
        this.router.navigateByUrl(
          '/stockmanaging/stocks/' + data.warehouse_id + '/' + data.product_id
        );
      }
    });
  }

  ngOnInit(): void {
    this.warehouseService.load().subscribe();
    this.productService.load().subscribe();
  }
  onSubmit(value: any) {
    this.stocks
      .upsert(_.pick(value, ['amount', 'product_id', 'warehouse_id']))
      .pipe(mergeMap(() => this.stocks.load()))
      .subscribe();
    console.log(value);
  }

  onSubmitSettings(product: any, value: any) {
    let body = {
      data: {
        ...product.data,
        stockmanagement: {
          ...value,
        },
      },
    };
    this.productService.updateProduct(product.id, body).subscribe();
  }
}
