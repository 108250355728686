import { Injectable } from '@angular/core';
import { ICart, Price } from '@app/data-interfaces';
import { CartService } from '@app/shared/data-cart2';

import { createStore, select, setProps, withProps } from '@ngneat/elf';
import {
  createRequestsCacheOperator,
  updateRequestCache,
  withRequestsCache,
} from '@ngneat/elf-requests';
import { RequestService } from '@pisci/requestManager';
import { Observable, map, tap } from 'rxjs';

interface CheckoutState {
  payment_methods: any[];
  shipment_options: any[];
}
const checkoutStore = createStore(
  { name: 'checkout' },
  withProps<CheckoutState>({ payment_methods: [], shipment_options: [] }),
  withRequestsCache(),
);

export const skipWhileCheckoutCached =
  createRequestsCacheOperator(checkoutStore);

@Injectable({
  providedIn: 'root',
})
export class Checkout2Service {
  cart$?: Observable<ICart>;
  itemCount$?: Observable<number>;
  isValid = false;
  showErrors = false;

  constructor(
    private cartService: CartService,
    private rm: RequestService,
  ) {
    this.itemCount$ = this.cartService.selectCountQuantity();
    this.cart$ = this.cartService.selectCart();
  }

  loadPaymentMethods() {
    this.rm
      .post('settingsValue/search', {
        filters: [{ field: 'key', operator: '=', value: 'payment_methods' }],
      })
      .pipe(
        skipWhileCheckoutCached('settingsValueSearch'),
        tap((result: any) => {
          if (result.data && result.data.length == 1) {
            checkoutStore.update(
              updateRequestCache('settingsValueSearch'),
              setProps({ payment_methods: result.data[0].data }),
            );
          }
        }),
      )
      .subscribe();
  }

  loadShipmentOptions() {
    this.rm
      .get('shipmentOptions')
      .pipe(
        skipWhileCheckoutCached('shipmentOptions'),
        tap((result: any) => {
          if (result.data) {
            checkoutStore.update(
              updateRequestCache('shipmentOptions'),
              setProps({ shipment_options: result.data }),
            );
          }
        }),
      )
      .subscribe();
  }

  selectShipmentOptions() {
    return checkoutStore.pipe(
      select((state) => state.shipment_options),
      map((shipment_options) =>
        shipment_options.map((so) => {
          if (so.price) {
            so.price = new Price(so.price);
          }
          return so;
        }),
      ),
    );
  }

  shipmentOptionSelected(shipmentOption: any) {
    this.cartService.setShipmentOption(shipmentOption);
  }

  selectPaymentMethods() {
    return checkoutStore.pipe(select((state) => state.payment_methods));
  }
}
