import { Component, OnInit } from '@angular/core';
import { ICategory, IVoucher } from '@app/data-interfaces';
import { ProductCatalogService } from '@app/shared/data-productscatalog';
import { VoucherService } from '@app/shared/data-vouchers';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss'],
})
export class VoucherComponent implements OnInit {
  editingCategory?: ICategory;
  addingCategory = false;
  vouchers$: Observable<any[]>;
  constructor(private voucherService: VoucherService) {
    this.vouchers$ = this.voucherService.getVouchers();
  }

  ngOnInit(): void {}

  getType(voucher: IVoucher) {
    return this.voucherService.getTypeLiteral(voucher);
  }
}
