import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IProduct, ISubscription, ISubscriptionActionType, Product, } from '@app/data-interfaces';
import { CartService } from '@app/shared/data-cart2';
import { ProductService } from '@app/shared/data-products';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map, Observable, of, OperatorFunction, take, tap } from 'rxjs';
import { format } from 'date-fns';
import { SubscriptionConfig, SubscriptionConfigService } from '../subscription-config.service';

import { MatDatepickerModule } from '@angular/material/datepicker';

import { MatIconModule } from '@angular/material/icon';

import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { NumberButtonInputComponent } from '../number-button-input/number-button-input.component';
import { MatLegacyInputModule as  MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
@UntilDestroy()
@Component({
  selector: 'subscription-modal',
  templateUrl: './subscription-modal.component.html',
  styleUrls: ['./subscription-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDatepickerModule,
    MatRadioModule,
    MatInputModule,
    ReactiveFormsModule,
    AsyncPipe,
    MatSelectModule,
    DatePipe,
    NumberButtonInputComponent,
    MatIconModule,
    MatLegacyFormFieldModule
  ]
})
export class SubscriptionModalComponent implements OnInit {
  product: Observable<IProduct>;
  showDateModal = false;
  title = 'Abo hinzufügen';
  intervalForm = new FormGroup({
    id: new FormControl<number | null>(null),
    interval_days: new FormControl(7),
    quantity: new FormControl<number>(1),
    product_id: new FormControl<number | null>(null),
    beginDateSelect: new FormControl('now'),
    startingFrom: new FormControl<string>(''),
  });
  config: SubscriptionConfig;
  helpDescription="";

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      product: IProduct;
      quantity?: number;
      subscription?: ISubscription;
      useCustomResult?: boolean,
      showDatePicker?: boolean
    },
    private ref: MatDialogRef<SubscriptionModalComponent>,
    private cartService: CartService,
    private productService: ProductService,
    private subscriptionConfig: SubscriptionConfigService,
  ) {
    this.config = this.subscriptionConfig.getConfig();
    if (data.showDatePicker) {
      this.config.showDatePicker = data.showDatePicker;
    }
    if (!this.config.showDatePicker && this.config.possibleDates) {

      this.intervalForm.patchValue({ startingFrom: this.getDateValueForForm(this.config.possibleDates[0]) })
    }

    if (data.subscription) {
      this.intervalForm.patchValue(data.subscription);
      this.product = this.productService
        .selectById(data.subscription.product_id)
        .pipe(
          filter((x) => x != null) as OperatorFunction<
            Product | null | undefined,
            Product
          >,
        );

      if(data.subscription.next_order) {
        const formDate = new Date(data.subscription.next_order);
        formDate.setHours(0, 0, 0, 0);
        this.intervalForm.patchValue({ startingFrom:  this.getDateValueForForm(formDate)});
        if(data.subscription.onCurrentOrder === true && this.config.possibleDates) {
          const nextDate = this.config.possibleDates[0];
          nextDate.setHours(0, 0, 0, 0);
          this.intervalForm.patchValue({startingFrom: this.getDateValueForForm(nextDate)})
        }
     //   console.log('next_order', this.intervalForm.value.startingFrom);
      }
      this.helpDescription = 'Abo-Änderungen sind mit den Schritten “Warenkorb”, "Zur Kasse" und "Jetzt zahlungspflichtig bestellen" zu bestätigen.';
      this.title = 'Speichern';
    } else {
      this.helpDescription = 'Das neu erstellte Abo ist mit den Schritten “Warenkorb”, "Zur Kasse" und "Jetzt zahlungspflichtig bestellen" zu bestätigen.';
      this.product = of(data.product);
      this.intervalForm.patchValue({
        product_id: data.product.id,
        quantity: data.quantity ?? 1,
      });
    }


  }

  ngOnInit(): void {
    this.intervalForm.valueChanges.pipe(untilDestroyed(this)).subscribe((v) => {
      this.showDateModal = v['beginDateSelect'] == 'later';
    });
  }

  clickAddUpdateSubscription() {
    const line = this.mergeSubscriptionWithForm()
    if (this.data.useCustomResult === true) {
      this.ref.close({ action: 'update', subscription:  line  })
      return;
    }


    let type: ISubscriptionActionType = 'add';
    if (this.data.subscription?.id) {
      type = 'update';
    }
    this.product
      .pipe(
        take(1),
        tap((product) => {
          this.cartService.addSubscriptionAction(
            { action: type, subscription: line },
            product,
          );
          this.ref.close();
        }),
      )
      .subscribe();
  }

  clickDeleteSubscription() {
    if (this.data.useCustomResult === true) {
      this.ref.close({ action: 'delete', subscription: this.data.subscription })
      return;
    }
    const line: Partial<ISubscription> = { ...this.data.subscription };
    const type: ISubscriptionActionType = 'remove';
    this.product.pipe(take(1)).subscribe((product) => {
      this.cartService.addSubscriptionAction(
        { action: type, subscription: line },
        product,
      );
      this.ref.close();
    });
  }

  hasDeleteButton() {
    return this.data.subscription != null;
  }

  clickClose() {
    this.ref.close();
  }

  getDeliveryDate(next_order: Date): Date {
    return this.subscriptionConfig.getDeliveryDate(next_order);
  }

  private mergeSubscriptionWithForm() {
    const line: Partial<ISubscription> = { ...(this.data.subscription) };
    if (this.intervalForm.value.interval_days) {
      line.interval_days = this.intervalForm.value.interval_days;
    }
    if (this.intervalForm.value.quantity) {
      line.quantity = this.intervalForm.value.quantity;
    }
    if (this.intervalForm.value.id) {
      line.id = this.intervalForm.value.id;
    }
    if (this.intervalForm.value.product_id) {
      line.product_id = this.intervalForm.value.product_id;
    }
    if (this.intervalForm.value.beginDateSelect == 'now') {
      line.next_order = format(new Date(), 'yyyy-MM-dd');
    } else if (this.intervalForm.value.startingFrom) {
      line.next_order =
        this.intervalForm.value.startingFrom;

    }
    if (!this.config.showDatePicker && this.intervalForm.value.startingFrom) {
      line.next_order = this.intervalForm.value.startingFrom;
    }
    return line;
  }

  getPossibleDates(): Date[] {
    if(!this.config.possibleDates) {
      return [];
    }

    return this.config.possibleDates.map((date) => {
      date.setHours(0, 0, 0, 0);
      return date;
    })
  }

  getDateValueForForm(date: Date) {
    return format(date, 'yyyy-MM-dd');
  }

  isOnOrder() {
    return this.cartService.selectSubscriptionLines().pipe(take(1), map((lines) => {
      return lines.some((line) => line.subscription_id === this.intervalForm.value.id);

    }));
  }

  clickPause() {
    this.cartService.selectSubscriptionLines().pipe(take(1), map((lines) => {
      const line = lines.find((line) => line.subscription_id === this.intervalForm.value.id);
      if (line) {
        this.cartService.updateSubscriptionLineQuantity(line, 0);
      }
    })).subscribe( () => {
      this.ref.close();
    });


  }
}
