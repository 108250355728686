import { inject, Injectable } from '@angular/core';
import { RequestService } from '@pisci/requestManager';

@Injectable({
  providedIn: 'root'
})
export class AdminSubscriptionService {
  rm = inject(RequestService);

  generateDraftOrders(date: string | Date) {
    return this.rm.post('subscription/generate', { date });
  }

  closeOpenDraftOrdersForToday() {
    return this.rm.post('subscription/closeDrafts', {  });

  }
}
