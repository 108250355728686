<nav>
  <ng-container *ngFor="let item of menuItems">
    <div class="menu-item">
      <a [routerLink]="item.routerLink">{{ item.label }}</a>
      <button *ngIf="item.subMenu" (click)="item.showSubMenu = !item.showSubMenu" class="toggle-button">+</button>
      <ng-container *ngIf="item.subMenu">
        <div class="sub-menu" [class.show]="item.showSubMenu">
          <ng-container *ngFor="let subItem of item.subMenu">
            <a [routerLink]="subItem.routerLink">{{ subItem.label }}</a>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</nav>
