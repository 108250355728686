<div class="modal">
  <h1>Abo Generieren Optionen</h1>
  <div>
    <mat-form-field>
      <mat-label>Choose a date</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="date">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div>
    <button class="btn btn-primary" (click)="generateSubscription()">Generieren</button>
  </div>
</div>
