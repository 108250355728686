import { Component, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { SettingsService } from '@cw/data-settings';
import { RequestService } from '@pisci/requestManager';
import * as _ from 'lodash';
import { forkJoin, tap } from 'rxjs';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  fb = inject(UntypedFormBuilder);
  invoiceForm = this.fb.group({
    name: this.fb.control(''),
    address: this.fb.control(''),
    image: this.fb.control(''),
    footer: this.fb.control(''),
  });
  invoiceNumber = this.fb.group({
    invoice: this.fb.group({
      number: this.fb.control(''),
    }),
    order: this.fb.group({
      number: this.fb.control(''),
    }),
    id: this.fb.control(''),
  });

  stripe = this.fb.group({
    test_mode: this.fb.control(false),
    public_key: this.fb.control(''),
    private_key: this.fb.control(''),

    public_key_test: this.fb.control(''),
    private_key_test: this.fb.control(''),
    webhook_secret: this.fb.control(''),
  });

  additional = this.fb.group({
    product_url: this.fb.group({
      data: this.fb.control(''),
      id: this.fb.control(''),
    }),
  });
  constructor(
    private rm: RequestService,
    private router: Router,
    private settingsS: SettingsService
  ) {}

  ngOnInit(): void {
    this.rm.get('mytenant/settings').subscribe((settings: any) => {
      this.invoiceForm.patchValue(settings.invoice);
      this.stripe.patchValue(settings.stripe);
    });
    this.rm.getAllPages('settingsValue').subscribe((data: any) => {
      const found = data.find((item: any) => item.key === 'number_generator');
      if (found) {
        this.invoiceNumber.patchValue({
          order: { number: found.data.order.number },
          invoice: { number: found.data.invoice.number },
          id: found.id,
        });
        console.log(found);
      }
      const product_url = data.find((item: any) => item.key === 'product_url');
      if (product_url) {
        this.additional.patchValue({
          product_url: { data: product_url.data, id: product_url.id },
        });
      }
    });
    console.log(this.additional);
  }
  save() {
    const requests = [];
    requests.push(
      this.rm.put('mytenant/settings', {
        invoice: this.invoiceForm.value,
        stripe: this.stripe.value,
      })
    );
    if (this.invoiceNumber.value.id && this.invoiceNumber.dirty) {
      requests.push(
        this.rm.put('settingsValue/' + this.invoiceNumber.value.id, {
          data: _.omit(this.invoiceNumber.value, 'id'),
        })
      );
    } else {
      requests.push(
        this.rm.post('settingsValue', {
          data: _.omit(this.invoiceNumber.value, 'id'),
          key: 'number_generator',
        })
      );
    }

    _.each(_.keys(this.additional.value), (control) => {
      const group = this.additional.get(control)?.value;
      if (group.id) {
        requests.push(
          this.rm.put('settingsValue/' + group.id, {
            data: group.data,
          })
        );
      } else {
        requests.push(
          this.rm.post('settingsValue', {
            data: group.data,
            key: control,
          })
        );
      }
    });

    forkJoin(requests).subscribe(() => {
      this.router.navigateByUrl('/');
    });
  }

  clickExportProducts() {
    this.rm
      .postGetFile('product/export', '')
      .pipe(
        tap((response: any) => {
          console.log(response);
          const blob = new Blob([response.body], {
            type: response.headers.get('content-type'),
          });
          //  let fileName =  response.headers.get('content-disposition').split(';')[0];
          const fileName = 'report.pdf';
          const file = new File([blob], fileName, {
            type: response.headers.get('content-type'),
          });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        })
      )
      .subscribe();
  }
}
