import { AfterContentInit, Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-expandable',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './expandable.component.html',
  styleUrls: ['./expandable.component.scss']
})
export class ExpandableComponent implements AfterContentInit{
  @Input()
  label: string = "Erweitern";
  expanded = false;

  clickExpand() {
    this.expanded = !this.expanded;
  }

  isExpanded() {
    return this.expanded
  }

  @ContentChild(TemplateRef) content!: TemplateRef<any>;
  hasContent = false;

  ngAfterContentInit() {
    this.hasContent = !!this.content;
  }
}
