import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ICart, ICartLine, ISubscriptionLineItemDTO, } from '@app/data-interfaces';
import { CartService, SubscriptionService } from '@app/shared/data-cart2';
import { VoucherService } from '@app/shared/data-vouchers';
import { Actions } from '@ngneat/effects-ng';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep, isNumber } from 'lodash';
import { EMPTY, Observable, take } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { addedVoucher, errorAddingVoucher } from '../cart-actions';
import {
  CartSubscriptionQuantityModalComponent
} from '../cart-subscription-quantity-modal/cart-subscription-quantity-modal.component';
import { SubscriptionModalComponent } from '@cw-addtocart';


export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: (ICartLine & {
  editQuantity: boolean;
  formControl: UntypedFormControl;
})[] = [];

@UntilDestroy()
@Component({
  selector: 'ui-mini-cart',
  templateUrl: './ui-mini-cart.component.html',
  styleUrls: ['./ui-mini-cart.component.scss'],
})
export class UiMiniCartComponent implements OnInit, OnDestroy {
  @Input()
  subscriptionEnabled = false;
  @Input()
  disableSubscriptionAction = false;

  @Output()
  checkoutClicked = new EventEmitter();

  @Input()
  removeIconPath = '';

  formVoucherControl = new UntypedFormControl('', Validators.required);

  vouchers$;

  subscription: any;
  dataSource = ELEMENT_DATA;
  subscriptionLines$: Observable<ISubscriptionLineItemDTO[]>;
  @Input("multiply-price") multiplyPrice = false;

  constructor(
    public cartService: CartService,
    public cd: ChangeDetectorRef,
    public voucherService: VoucherService,
    private actions: Actions,
    private modalService: MatDialog,
    private subscriptionService: SubscriptionService
  ) {
    this.vouchers$ = this.cartService.selectVouchers();
    this.subscriptionLines$ = this.cartService.selectSubscriptionLines();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.cartService
      .selectCart()
      .pipe(untilDestroyed(this))
      .subscribe((cart: ICart) => {
        this.dataSource = cart.lines.map((element: ICartLine) => {
          const myelement = {
            ...cloneDeep(element),
            editQuantity: false,
            formControl: new UntypedFormControl(element.quantity),
          };

          myelement.formControl.valueChanges.subscribe(
            this.updateElement.bind(this, element),
          );

          myelement.formControl.valueChanges
            .pipe(
              tap((value: any) => {
                if (value == '') {
                  //    setTimeout(() => {myelement.formControl.setValue(1);}, 10);
                }
              }),
            )
            .subscribe();
          return myelement;
        });
        console.log('updatedDataSource');
      });
    this.cartService.requestCartUpdate()
  }

  isSubscriptionLine(element: ICartLine) {
    return element.options?.subscriptionLine ?? false;
  }

  clickEditQuantitySubscriptionLine(element: ISubscriptionLineItemDTO) {
    if (element.subscription_id) {
      this.modalService.open(CartSubscriptionQuantityModalComponent, {
        data: { subscriptionLine: element },
      });
    }

    return false;
  }

  isEditSubscriptionButtonDisabled(element: ISubscriptionLineItemDTO) {
    return !element.subscription_id;
  }

  removeVoucher(v: any) {
    this.cartService.removeVoucher(v);
  }

  lostFocus(element: any) {
    if (!isNumber(element.formControl.value)) {
      element.formControl.setValue(1);
    }
    if (element.quantity !== element.formControl.value) {
      this.cartService.updateQuantityByLineId(
        element.id,
        element.formControl.value,
      );
    }
  }

  removeLineClicked(line: ICartLine) {
    this.cartService.removeLine(line);
  }

  goToCheckoutClicked() {
    this.checkoutClicked.next(true);
  }

  editQuantity(element: any) {
    element.formControl.setValue(element.quantity, { emitEvents: false });
    element.editQuantity = true;
  }

  updateElement(element: any, value: any) {
    if (!isNumber(value)) return;
    if (element.quantity !== value) {
      this.cartService.updateQuantityByLineId(element.id, value);
    }
  }

  isElementEditable(element: any) {
    return element.voucherCode == undefined || element.voucherCode == null;
  }

  clickLeft(element: any) {
    if (!isNumber(element.formControl.value)) {
      element.formControl.setValue(1);
      return;
    }
    if (element.formControl.value <= 1) {
      return;
    }
    element.formControl.setValue(
      Number.parseFloat(element.formControl.value) - 1,
    );
  }

  clickRight(element: any) {
    if (!isNumber(element.formControl.value)) {
      element.formControl.setValue(1);
      return;
    }
    element.formControl.setValue(
      Number.parseFloat(element.formControl.value) + 1,
    );
  }

  addVoucher() {
    this.cartService
      .addVoucher(this.formVoucherControl.value)
      .pipe(
        catchError((e: any) => {
          console.log('error function');
          this.actions.dispatch(errorAddingVoucher());
          return EMPTY;
        }),
        tap(() => {
          this.actions.dispatch(addedVoucher());
        }),
      )
      .subscribe();
  }

  clickSubscriptionButton(line: ISubscriptionLineItemDTO): void {
    if(this.disableSubscriptionAction === true) {
      return;
    }
    if (line.id && typeof line.id === 'string') {
      this.cartService.selectSubscription(line.id).pipe(take(1)).subscribe(
        (subAction) => {
          if (!subAction) return;
          this.modalService.open(SubscriptionModalComponent, {
            data: { subscription: subAction },
          });

        });
      return;
    }

  }

  getProductLink(productId: number) {
    return '/product/'+productId;
  }
}
